import { capitalizeFirstLetter, imagesLink } from "@/helpers/dataHelper";
import { Badge, Card, Rate } from "antd";
import Link from "next/link";
import React, { FC } from "react";
import { ArrowRightOutlined } from '@ant-design/icons';

interface ICourseTest {
    name: string
}
interface ICourseObjects {
    slug: string,
    name: string,
    image: string,
    average_rating: number,
    test: ICourseTest,
    total_feedback: number
    is_free: boolean,
    price: number,
    discount_percentage: any
}

type CourseSingleItemProps = {
    course: ICourseObjects,
    relatedCourses?: boolean,
    isDetailPage?: boolean,
    isCarousel?: boolean
}

const CourseSingleItem: FC<CourseSingleItemProps> = ({ course }) => {
  return (
    <Link href={`/course/${course.slug}`} key={course?.slug}>
      <a title={capitalizeFirstLetter(course?.name)}>
        <Card
          className={`cursor-pointer course-item-card course-card shadow-none rounded mb-1 montserrat-font-family`}
          cover={
            <img
              alt={course?.name}
              style={{ height: '135px' }}
              src={
                course.image
                  ? course.image
                  : imagesLink[Math.floor(Math.random() * imagesLink.length)]
              }
            />
          }
        >
          <span className="ellipsis-clamp-2 course-single-name font-weight-500">
            {capitalizeFirstLetter(course?.name)}
          </span>
          <div className="mt-2 mb-2">
            <span className="course-instructor d-block">
              {course?.test?.name ? (
                <span className="text-muted" style={{ lineHeight: '0.5' }}>
                  {capitalizeFirstLetter(course?.test?.name)}
                </span>
              ) : null}
            </span>
            <div className="d-flex justify-content-between">
              <span className="course-rating mt-1">
                {course?.total_feedback != 0 ? (
                  <>
                    <strong
                      className="mr-1 mt-1"
                      style={{
                        fontSize: '12px',
                        color: '#fbbc05',
                      }}
                    >
                      {course?.average_rating?.toFixed(1)}
                    </strong>
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={course?.average_rating}
                      style={{
                        cursor: 'pointer',
                        fontSize: '13px',
                        color: '#fbbc05',
                      }}
                    />
                    <small
                      className="ml-1 mt-1 text-muted font-weight-bold reviewtip"
                      style={{
                        fontSize: '12px',
                        lineHeight: '1.8',
                        fontFamily: 'Poppins sans-serif',
                      }}
                    >
                      ({course?.total_feedback})
                      <span className="tooltiptext">No. of Review</span>
                    </small>
                  </>
                ) : null}
              </span>
            </div>
          </div>
          <div className="mt-3 mb-3 border-bottom-dotted" />
          <div className="position-relative d-flex justify-content-between">
            <div className="d-flex flex-wrap align-items-center mt-1">
              {course?.is_free ? (
                <div className="price-text">
                  <span className="text-dark text-uppercase">Free</span>
                </div>
              ) : (
                <>
                  <span className="text-dark text-uppercase">
                    Rs.
                    {(
                      course?.price -
                      (course?.discount_percentage / 100) * course?.price
                    ).toFixed(2)}{' '}
                  </span>
                  {course?.discount_percentage !== null &&
                    parseInt(course?.discount_percentage as string) !== 0 && (
                      <del className="ml-2 text-muted">
                        Rs.
                        {course?.price}
                      </del>
                    )}
                </>
              )}
              {/* Discount */}
              {!course?.is_free && parseInt(course?.discount_percentage) !== 0 && (
                <div className="discount-badge">
                  <Badge.Ribbon
                    className="shadow text-center"
                    text={`-${course?.discount_percentage}%`}
                  />
                </div>
              )}
            </div>
            <div
              className="go-to-course float-right position-absolute"
              style={{
                right: '6%',
              }}
            >
              <ArrowRightOutlined
                rev=""
                style={{ fontSize: '20px', color: '#283360' }}
              />
            </div>
          </div>
        </Card>
      </a>
    </Link>
  );
};

export default CourseSingleItem;