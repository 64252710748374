import useWidth from '@/helpers/widthUtil';
import { Carousel } from 'antd';
import { CSSProperties, FC, MouseEventHandler } from 'react';

type CustomAntCarouselProps = {
  children?: JSX.Element[] | JSX.Element,
  customSettings?: object,
  relatedCourses?: boolean,
  isDetailPage?: boolean,
  isLandingBlog?: boolean
}

const CustomAntCarousel: FC<CustomAntCarouselProps> = ({ children, customSettings, relatedCourses = false, isDetailPage = false, isLandingBlog = false }) => {
  const { isMobile, isTablet, isLaptop } = useWidth();
  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: isMobile ? 1 : isTablet ? 2 : 3,
    slidesToScroll: isMobile ? 1 : isTablet ? 2 : 3,
    autoplay: true,
    arrows: (isMobile) ? false : true,
  };
  return (
    <div className={`${isTablet ? 'tablet-carousel ' : relatedCourses ? 'relatedCoursesCarousel' : isDetailPage ? 'isDetailPageCarousel' : ''} custom-ant-carousel`}>
      <Carousel pauseOnHover {...(customSettings ? customSettings : settings)}>
        {children}
      </Carousel>
    </div>
  );
};

export default CustomAntCarousel;

export const NextArrow = (props) => {
  const {
    className,
    onClick,
  }: { className: string; onClick: MouseEventHandler<HTMLDivElement> } = props;
  return (
    <div className={`${className} arrow-right border-18 p-3`} onClick={onClick}>
      <img src="/images/right.svg" width={'64px'} />
    </div>
  );
};

export const PrevArrow = (props) => {
  const {
    className,
    onClick,
  }: {
    className: string;
    style: CSSProperties;
    onClick: MouseEventHandler<HTMLDivElement>;
  } = props;

  return (
    <div className={`${className} p-3 border-18 arrow-left`} onClick={onClick}>
      <img src="/images/left.svg" width={'64px'} />
    </div>
  );
};
