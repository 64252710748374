import React, { useEffect, useState } from 'react';
import { Button, Empty, Pagination, Table } from 'antd';

import { Container } from 'reactstrap';
import { getQuizAnswerSheet, getQuizSummary } from '@/actions/quizAction';
import { useDispatch, useSelector } from 'react-redux';
import QuizSummary from './quizSumarryModal';
import CustomAntSearch from '@/components/CustomAntSearch';
import Loader from '@/components/common/Spinner';

const MyQuizes = ({ user = '' }) => {
  const dispatch = useDispatch();

  const [query, setQuery] = useState({
    page: 1,
    per_page: user ? 10 : 5,
    course: '',
    user: user,
  });
  const [visible, setVisible] = useState(false);

  const myQuiz = useSelector((state: any) => state.quizReducer?.myAnswer);
  const quizSummary = useSelector((state: any) => state.quizReducer?.quizSummary);

  useEffect(() => {
    dispatch(getQuizAnswerSheet(query));
  }, [query]);

  const searchMyQuiz = (e) => {
    setQuery({ ...query, course: e.target.value });
    dispatch(getQuizAnswerSheet(query));
  };
  const viewDetail = (slug) => {
    setVisible(true);
    dispatch(getQuizSummary(slug));
  };

  const handleQuizPagination = (page: any, pageSize: any) => {
    setQuery({ ...query, page, per_page: pageSize });
  };
  const columns = [
    {
      title: 'S.N.',
      dataIndex: 'sn',
      key: 'sn',
    },
    {
      title: 'Course',
      dataIndex: 'course',
    },
    {
      title: 'Chapter',
      dataIndex: 'chapter',
    },
    {
      title: 'Correct',
      dataIndex: 'correct',
    },
    {
      title: 'Incorrect',
      dataIndex: 'incorrect',
    },
    {
      title: 'Unattempt',
      dataIndex: 'unattemps',
    },
    {
      title: 'Score',
      dataIndex: 'score',
    },
    {
      title: 'Action',
      dataIndex: 'viewdetail',
    },
  ];
  const data =
    myQuiz?.data?.data?.length !== 0 &&
    myQuiz?.data?.data?.map((each, index) => ({
      key: each?.id,
      sn: `${
        (myQuiz?.data?.meta?.page - 1) * myQuiz?.data?.meta?.per_page + index + 1
      }`,

      course: (
        <span className="text-muted text-capitalize">{each?.quiz?.course}</span>
      ),
      chapter: (
        <span className="text-muted text-capitalize">{each?.quiz?.chapter}</span>
      ),
      attempted: (
        <span className="text-muted text-capitalize">{each?.attempted}</span>
      ),
      unattemps: (
        <span className="text-muted text-capitalize">{each?.unattempted}</span>
      ),
      correct: <span className="text-muted text-capitalize">{each?.correct}</span>,
      incorrect: (
        <span className="text-muted text-capitalize">{each?.incorrect}</span>
      ),
      score:
        parseInt(each?.percentage) === 100 ? (
          <img src="/images/check.svg" width={'28px'} height="28px" />
        ) : (
          `${each?.percentage}%`
        ),
      viewdetail: (
        <Button
          size="small"
          className="btn-outline-primary border-10 border-danger"
          onClick={() => viewDetail(each?.quiz?.slug)}
        >
          <span className="d-flex justify-content-between">
            View <i className="fas fa-eye float-right ml-1 my-auto"></i>
          </span>
        </Button>
      ),
    }));
  return (
    <Container>
      <h3 className="text-dark mb-3 col">
        {' '}
        <img
          src={'/images/svgs/dashboard/mock test stroke.svg'}
          width={'32px'}
          height="32px"
        />{' '}
        My Attempted Quiz
      </h3>
      {myQuiz?.data?.data?.length !== 0 ? (
        <>
          <div className="col-lg-4 col-md-6 col-sm-8 mb-2 ml-auto">
            <CustomAntSearch
              placeholder="Search My Quiz"
              onChange={(e) => searchMyQuiz(e)}
            />
          </div>

          <Table
            className="quiz-section"
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={{
              indicator: (
                <div>
                  <Loader />
                </div>
              ),
              spinning: myQuiz?.loading,
            }}
          />
          {!myQuiz?.loading ? (
            <Pagination
              className="mt-4 d-flex justify-content-center "
              pageSize={query?.per_page}
              total={myQuiz?.data?.meta?.total}
              defaultCurrent={query?.page}
              onChange={handleQuizPagination}
            />
          ) : null}
        </>
      ) : (
        <Empty description="No Quiz Found" />
      )}
      <QuizSummary
        quizDetail={quizSummary}
        visible={visible}
        cancel={() => setVisible(false)}
      />
    </Container>
  );
};

export default MyQuizes;
