import { Modal } from 'antd';
import React from 'react';
import MyQuizDetail from './myQuizDetail';

const QuizSummary = ({ visible, cancel, quizDetail }) => {
    return (
        <>
            <Modal
                className='quiz-summary-modal'
                title={
                    <>
                        <h3 className='heading text-center'>Your Quiz Summary</h3>
                        <span className="border-bottom bg-dark"></span>
                    </>
                }
                visible={visible}
                onCancel={cancel}
                footer={false}
                width={1000}
            >
                <MyQuizDetail quizDetail={quizDetail} />
            </Modal>
        </>
    )
};

export default QuizSummary;
