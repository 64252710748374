import {
  GET_QUESTION,
  GET_QUESTION_SUCCEDED,
  GET_QUESTION_FAIL,
  ADD_QUIZ_ANSWER_SHEET,
  ADD_QUIZ_ANSWER_SHEET_SUCCEEDED,
  ADD_QUIZ_ANSWER_SHEET_FAILED,
  GET_QUIZ_ANSWER_SHEET,
  GET_QUIZ_ANSWER_SHEET_SUCCEEDED,
  GET_QUIZ_ANSWER_SHEET_FAILED,
  GET_QUIZ_SUMMARY,
  GET_QUIZ_SUMMARY_SUCCEEDED,
  GET_QUIZ_SUMMARY_FAILED,
  SET_QUIZ_EDIT_DATA,
  SET_QUIZ_COMPLETE_DATA,
} from './index';

export const getQuiz = (slug) => {
  return {
    type: GET_QUESTION,
    payload: {
      url: `quiz/${slug}/`,
      onSuccess: GET_QUESTION_SUCCEDED,
      onFailure: GET_QUESTION_FAIL,
    },
  };
};

export const addQuizAnswerSheet = (data = {}) => {
  return {
    type: ADD_QUIZ_ANSWER_SHEET,
    payload: {
      data,
      url: `enrol/quiz-answer-sheet/`,
      onSuccess: ADD_QUIZ_ANSWER_SHEET_SUCCEEDED,
      onFailure: ADD_QUIZ_ANSWER_SHEET_FAILED,
    },
  };
};

export const getQuizAnswerSheet = (data = {}) => {
  let query = '';
  if (data) {
    Object.keys(data).map((each) => {
      query = `${query}&${each}=${data[each]}`;
    });
  }
  return {
    type: GET_QUIZ_ANSWER_SHEET,
    payload: {
      url: `enrol/quiz-answer-sheet/?${query}`,
      onSuccess: GET_QUIZ_ANSWER_SHEET_SUCCEEDED,
      onFailure: GET_QUIZ_ANSWER_SHEET_FAILED,
    },
  };
};

export const getQuizSummary = (slug = {}) => {
  return {
    type: GET_QUIZ_SUMMARY,
    payload: {
      url: `enrol/quiz-answer-sheet/${slug}/`,
      onSuccess: GET_QUIZ_SUMMARY_SUCCEEDED,
      onFailure: GET_QUIZ_SUMMARY_FAILED,
    },
  };
};

export const setQuizEditData = (data = {}) => {
  return {
    type: SET_QUIZ_EDIT_DATA,
    payload: {
      data,
    },
  };
};

export const setQuizEditCompleteData = (data = {}) => {
  return {
    type: SET_QUIZ_COMPLETE_DATA,
    payload: {
      data,
    },
  };
};
