import { Input } from "antd";
import React from "react";

const CustomAntSearch = ({ onChange = null, placeholder = "Search here" }) => {
    return (
        <div className="custom-search">
            <Input.Search
                placeholder={placeholder}
                enterButton
                allowClear
                onChange={(e) => onChange(e)}
            />
        </div>
    )
};

export default CustomAntSearch;