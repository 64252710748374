import React from 'react';

// import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
const _ = String.raw;

const KatexViewer = ({ text }) => {
  return (
    <TeX block className="output" settings={{ macros: { '*': _`\cdot` } }}>
      {text}
    </TeX>
  );
};

export default KatexViewer;
