import {
  capitalizeFirstLetter,
  generateStrokeColor,
  imagesLink,
} from '@/helpers/dataHelper';
import useWidth, { IBooleanArgs } from '@/helpers/widthUtil';
import { Progress } from 'antd';
import { NextRouter, useRouter } from 'next/router';
import { FC, memo } from 'react';
import { Card, CardBody, CardImg, CardTitle, Col, Row } from 'reactstrap';

interface CourseProps {
  id: number;
  course__name: string;
  course__image: string;
  course__slug: string;
  last_content: {
    id: number;
    chapter: number;
    subject: number;
    name: string;
  };
  subject: number;
  chapter: number;
  enroll_percentage: number | string;
}

type LandingLearningsProps = {
  each?: CourseProps;
  myLearnings?: boolean;
};

const LandingLearnings: FC<LandingLearningsProps> = ({ each }) => {
  const router: NextRouter = useRouter();
  const { isMobile }: IBooleanArgs = useWidth();

  return (
    <Card
      className="cursor-pointer shadow-none box-shadow-hover"
      style={{
        width:
          typeof window !== 'undefined' && window?.innerWidth <= 1560
            ? '19rem'
            : '22rem',
        height: !isMobile ? '8rem' : 'auto',
      }}
      key={each?.id}
      title={each?.course__name}
      onClick={() =>
        router.push(
          each?.last_content?.subject
            ? {
                pathname: `/course/lesson/${each?.course__slug}/`,
                query: {
                  subtab: each?.last_content?.subject,
                  chaptab: each?.last_content?.chapter,
                  activetab: each?.last_content?.id,
                },
              }
            : {
                pathname: `/course/lesson/${each?.course__slug}/`,
                query: {
                  subtab: each?.subject,
                  chaptab: each?.chapter,
                  activetab: each?.id,
                },
              }
        )
      }
    >
      <Row className="no-gutters">
        <Col md="5">
          <CardImg
            width="100%"
            src={
              each?.course__image
                ? each?.course__image
                : imagesLink[Math.floor(Math.random() * imagesLink.length)]
            }
            alt={each?.course__name}
            className={`rounded-0 border border-muted border-right-0 ${
              isMobile ? '' : 'border-left-sm'
            }`}
            style={{ height: '8rem' }}
          />
          <div className="course-preview-play bg-white">
            <i
              className="fas fa-play-circle"
              style={{ color: 'rgba(0, 0, 0,0.5)' }}
            ></i>
          </div>
        </Col>
        <Col
          md="7"
          className={`border border-muted ${
            isMobile ? 'border border-top-0' : 'border-left-0 border-right-sm'
          }`}
        >
          <CardBody className="font-size-sm">
            <CardTitle className="text-black font-weight-500 ellipsis-clamp-2">
              {each?.course__name}
            </CardTitle>
            <div className="d-flex justify-content-between align-items-center">
              {each?.last_content && (
                <span className="my-auto ">
                  <span className="font-weight-normal font-size-sm">
                    {capitalizeFirstLetter(each?.last_content)}
                  </span>
                </span>
              )}
            </div>
            <Progress
              className={!isMobile ? 'position-absolute font-size-sm' : ''}
              style={!isMobile ? { bottom: '5%', width: '85%' } : {}}
              strokeColor={generateStrokeColor(
                parseInt(each?.enroll_percentage as string)
              )}
              percent={each?.enroll_percentage as number}
              width={40}
            />
          </CardBody>
        </Col>
      </Row>
    </Card>
  );
};

export default memo(LandingLearnings);
